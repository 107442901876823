<script lang="ts">
	import { description, title } from "$lib/constants";
	import "../app.css";
</script>

<svelte:head>
	<title>{title}</title>
	<meta name="description" content={description} />
</svelte:head>

<div class="flex h-full w-full flex-col gap-2 overflow-hidden">
	<header class="flex items-center justify-center bg-[#bfe4f0] p-4">
		<img src="/Voice_Stuido_banner.png" class="h-20 object-contain max-sm:hidden" alt="LOGO" />
		<div class="mx-auto sm:hidden">
			<img src="/Voice_Stuido_banner.png" class="h-8 object-contain" alt="LOGO" />
			<p class="text-secondary mt-2 text-center text-xs">部分功能在小螢幕上可能受限</p>
		</div>
	</header>

	<slot />

	<footer class="bg-primary flex h-[8rem] place-items-center gap-4 text-white">
		<div class="flex h-[7rem] place-items-center gap-4 p-6 max-sm:hidden">
			<a href="https://www.edu.tw/" target="_blank" class="h-[50px] object-contain">
				<img src="/moe.png" class="h-[50px] object-contain" alt="moe" />
			</a>
			<a href="https://www.ntnu.edu.tw/" target="_blank" class="h-[50px] object-contain">
				<img src="/ntnu_logo_red.png" class="h-[50px] object-contain" alt="ntnu_logo_red" />
			</a>
		</div>
		<div class="flex">
			<p class="px-2 text-start text-lg leading-5 max-sm:text-base">
				<br />
				Copyright ©2023 Cool English<br />
				Funded by Ministry of Education<br />
				Created by Department of English, National Taiwan Normal University<br />
				<br />
			</p>
		</div>
		<div class="h-[7rem] grow place-items-center justify-center p-6 max-sm:hidden">
			<div class="flex h-[40px] place-items-center justify-center">
				<a
					href="https://www.coolenglish.edu.tw/"
					target="_blank"
					class="h-full object-contain"
				>
					<img
						src="/coolenglish_logo.png"
						class="h-full object-contain"
						alt="coolenglish_logo"
					/>
				</a>
			</div>
		</div>
	</footer>
</div>
